import * as React from 'react'
import { Link } from 'gatsby'
import { Typography } from '@mui/material'
import Layout from '../layouts'
import Seo from '../components/seo'
import PageWrap from '../components/pageWrap'
import Footer from '../components/footer'
import Topbar from '../components/topbar'

const NotFoundPage = ({ pageContext }) => (
  <Layout>
    <Topbar lang={pageContext.langKey} />
    <PageWrap>

      <Typography
        variant='h1'
      >
        404: Page not found
      </Typography>
      <Typography variant='body1'>Sorry, but this is a dead end.</Typography>
      <Typography
        variant='p'
        sx={{
          'a': {
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
              color: 'primary.dark'
            }
          }
        }}
      >
        <Link to={'/'}>Back to start</Link>
      </Typography>

    </PageWrap>
    <Footer />
  </Layout>
)

export const Head = () => <Seo title="404: Page not found" />

export default NotFoundPage
